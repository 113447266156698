import React from "react"
import { graphql } from "gatsby"
import LayoutContainer from "../components/layout"
import { useQuery, useMutation } from "@apollo/react-hooks"
import { DO_ACTIVITY, USER_PROGRESS } from "../gql"
import Throbber from "../components/throbber"
import Breadcrumb from "../components/breadcrumb"
import InspirationCard from "../components/inspiration-card"
import { USER_JOURNEYS_ACTIVITIES } from "../gql/user"
import useCurrentLang from "../hooks/useCurrentLang"
import { withTranslation, useTranslation } from "react-i18next"
import JourneySection from "../components/journey-section"

export const allJourneyActivities = graphql`
  query journeyActivities($id: String) {
    contentfulJourney(id: { eq: $id }) {
      title
      title_cs
      title_de
      title_es
      title_fr
      title_hu
      title_it
      title_ja
      title_ko
      title_nl
      title_pl
      title_pt
      title_th
      title_hk
      slug
      color
      illustration {
        file {
          url
        }
      }
      category {
        color
      }
      inspiration {
        title
        slug
        subtitle {
          subtitle
        }
      }
      activities {
        contentful_id
        title
        title_cs
        title_de
        title_es
        title_fr
        title_hu
        title_it
        title_ja
        title_ko
        title_nl
        title_pl
        title_pt
        title_th
        title_hk
        subtitle
        subtitle_cs
        subtitle_de
        subtitle_es
        subtitle_fr
        subtitle_hu
        subtitle_it
        subtitle_ja
        subtitle_ko
        subtitle_nl
        subtitle_pl
        subtitle_pt
        subtitle_th
        subtitle_hk
        illustration {
          file {
            url
          }
        }
        points
        slug
      }
    }
  }
`

const JourneyTemplate = ({ data, pageContext, location }) => {
  const { t } = useTranslation()
  const { code: langCode } = useCurrentLang()
  const { data: backendData, loading } = useQuery(USER_JOURNEYS_ACTIVITIES)

  const [doActivity] = useMutation(DO_ACTIVITY, {
    refetchQueries: [
      {
        query: USER_JOURNEYS_ACTIVITIES,
      },
      {
        query: USER_PROGRESS,
      },
    ],
  })
  // const { doActivity, data: updatedData } = useActivityMutations()
  const {
    contentfulJourney: {
      color,
      title,
      // category,
      inspiration,
      illustration,
      slug,
      activities,
      ...restData
    },
  } = data
  const { color: headerColor } = {}
  const [allActivities, setAllActivities] = React.useState([])

  React.useEffect(() => {
    // When the backend data is available (aka. the activity progress), we merge it with the activity content from Contentful.
    // This allows us to just have 1 array of activities, with each object containing all relevant data.
    if (backendData && backendData.me) {
      const myJourney = backendData.me.journeys.find(journey => {
        return journey.contentfulId === pageContext.contentful_id
      })
      if (myJourney) {
        const backendActivities = myJourney.activities
        const mergedActivities = activities.map(activity => {
          // Locate the correct activity from the backendActivities by id
          const activityProgress = backendActivities.find(
            backendActivity =>
              backendActivity.contentfulId === activity.contentful_id &&
              backendActivity
          )
          // Create an object with all the activity data and the journey data necessary to make the activity card.
          return {
            ...activityProgress,
            ...activity,
            journeyColor: color,
            journeyTitle: title,
            journeySlug: slug,
            journeyIllustration: illustration,
            headerColor,
            activity,
          }
        })

        setAllActivities(mergedActivities)
      } else {
        setAllActivities([])
      }
    }
  }, [backendData])

  const getActiveAndInactiveActivities = () => {
    return allActivities.filter(activity => !activity.completed)
  }

  const getCompletedActivities = () => {
    return allActivities.filter(activity => activity.completed)
  }

  const onDoActivity = contentfulId => {
    const variables = {
      contentfulId: contentfulId,
    }
    doActivity({
      variables: variables,
    })
  }

  return (
    <LayoutContainer path={location.pathname} seoTitle="Journey overview">
      <Breadcrumb location={location} />
      {loading ? (
        <Throbber relative loadingText="Loading" />
      ) : (
        <>
          {getActiveAndInactiveActivities().length > 0 && (
            <JourneySection
              title={t("activities-page.header", {
                activityHeader:
                  restData[`title_${langCode === "zh" ? "hk" : langCode}`] ||
                  title,
              })}
              //       subtitle={`GO GO GO. Practice the activities you started and keep track of your
              // progress.`}
              onDoActivity={onDoActivity}
              activities={getActiveAndInactiveActivities()}
            />
          )}
          {/* {getInactiveActivities().length > 0 && (
            <JourneySection
              title={`${
                getActiveActivities().length > 0 ? "More" : "" // If the user has any active activities, display "More".} ${title} activities`}
              subtitle={`Explore the ${title} activities below and get started.`}
              onDoActivity={onDoActivity}
              activities={getInactiveActivities()}
            />
          )} */}
          {getCompletedActivities().length > 0 && (
            <JourneySection
              title={t("completed-activity.header", {
                headerTitle:
                  restData[`title_${langCode === "zh" ? "hk" : langCode}`] ||
                  title,
              })}
              subtitle={t("completed-activity.sub-header")}
              onDoActivity={onDoActivity}
              activities={getCompletedActivities()}
            />
          )}
          {inspiration && (
            <div className="row margin--top-100">
              <h1 className="big-title uppercase center no-margin--bottom">
                {`${title} inspiration`}
              </h1>
              <p className="col s12 l8 offset-l2 center text">{`Learn about the science and theory related to your activities through inspiring talks, articles, podcasts, books, and suggested apps.`}</p>
              <div className="col l4 s12 offset-l4">
                <InspirationCard
                  title={inspiration.title}
                  subtitle={inspiration.subtitle}
                  slug={inspiration.slug}
                  journeyTitle={title}
                  headerColor={headerColor}
                  color={color}
                  illustration={illustration}
                  journeySlug={slug}
                />
              </div>
            </div>
          )}
        </>
      )}
    </LayoutContainer>
  )
}

export default withTranslation()(JourneyTemplate)
