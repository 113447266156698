import React from "react"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"

const Breadcrumb = ({ location }) => {
  const { t } = useTranslation()
  const toTitleCase = str => {
    return str
      .toLowerCase()
      .split(" ")
      .map(function(word) {
        return word.charAt(0).toUpperCase() + word.slice(1)
      })
      .join(" ")
  }

  const path = location.pathname.split("/") // Split the path into an array
  path.shift() // Remove the first element as it is an empty string
  const breadCrumb = path.map((pathName, index) => {
    let fullPath
    switch (index) {
      // Could be expanded to support more levels
      case 0:
        fullPath = `${path[0]}`
        break
      case 1:
        fullPath = `${path[0]}/${path[1]}`
        break
      case 2:
        fullPath = `${path[0]}/${path[1]}/${path[2]}`
        break
      default:
        fullPath = `${path[0]}`
    }
    const label = t(`journeySlugs.${pathName}`)
    const crumb = {
      to: `/${fullPath}`,
      label: label,
    }
    return crumb
  })

  return (
    <div className="breadcrumb-wrapper">
      {breadCrumb.map(crumb => {
        return (
          <Link key={crumb.to} className="breadcrumb" to={crumb.to}>
            {toTitleCase(crumb.label)}{" "}
            <svg
              className="right-arrow"
              width="7"
              height="10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g fill="#000" fillRule="evenodd">
                <path d="M.586 7.828l4.242-4.242L6.243 5 2 9.243z" />
                <path d="M2 .757L6.243 5 4.828 6.414.586 2.172z" />
              </g>
            </svg>{" "}
          </Link>
        )
      })}
    </div>
  )
}

export default Breadcrumb
