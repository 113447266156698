import React from "react"
import { Link } from "gatsby"
// import ActivityProgress from "./activity-progress"
import { Trans, withTranslation } from "react-i18next"
import { APP_ROUTES } from "../constants/route"
import useCurrentLang from "../hooks/useCurrentLang"

const ActivityCard = ({
  onDoActivity,
  progressLoading,
  contentfulId,
  title,
  illustration,
  subtitle,
  journeyIllustration,
  journeyTitle,
  journeyColor,
  headerColor,
  slug,
  journeySlug,
  active,
  completed,
  progress,
  progressLeft,
  points,
  activity,
  ...rest
}) => {
  const { code: langCode } = useCurrentLang()
  return (
    <div className="card__wrapper" style={{ backgroundColor: journeyColor }}>
      <div
        className="card__header"
        style={{ backgroundColor: headerColor, color: journeyColor }}
      >
        {rest[`title_${langCode === "zh" ? "hk" : langCode}`] || journeyTitle}
      </div>
      <div className="progress__point__circle progress__point__circle--small ">
        {completed ? (
          <svg width="14" height="12" xmlns="http://www.w3.org/2000/svg">
            <g fill="#000" fillRule="evenodd">
              <path d="M2.05 5.707L6.293 9.95l-1.414 1.414L.636 7.121z" />
              <path d="M13.364 2.879l-8.485 8.485L3.464 9.95l8.486-8.486z" />
            </g>
          </svg>
        ) : (
          points
        )}
      </div>

      <img
        className="card__image margin--top-24"
        src={
          illustration ? illustration.file.url : journeyIllustration.file.url
        }
        alt=""
      />
      <div className="card__text">
        <h2 className="card__title no-margin--bottom margin--top-16">
          {rest[`title_${langCode === "zh" ? "hk" : langCode}`] || title}
        </h2>
        <p className="card__subtitle">
          {rest[`subtitle_${langCode === "zh" ? "hk" : langCode}`] || subtitle}
        </p>
        <Link
          to={`${APP_ROUTES.journeys}/${journeySlug}/${slug}`}
          className="button card__button "
        >
          <Trans i18nKey="activity-cards.explore" />
        </Link>
        {/* {active && (
          <ActivityProgress
            doActivity={() => onDoActivity(contentfulId)}
            progressLoading={progressLoading}
            isPreview
            progress={progress}
            progressLeft={progressLeft}
          />
        )} */}
      </div>
    </div>
  )
}

export default withTranslation()(ActivityCard)
