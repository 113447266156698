import React from "react"
import cx from "classnames"
import { Link } from "gatsby"
import { Trans } from "react-i18next"
import { APP_ROUTES } from "../constants/route"

const InspirationCard = ({
  journeyTitle,
  headerColor,
  color,
  title,
  subtitle,
  illustration,
  journeySlug,
  slug,
  horizontalSpace,
  subtitleMinHeight,
}) => {
  return (
    <div
      style={{ backgroundColor: color }}
      className={cx("card__wrapper", {
        "card__wrapper--horizontal-space": horizontalSpace,
      })}
    >
      <div
        className="card__header"
        style={{ backgroundColor: headerColor, color: color }}
      >
        {journeyTitle}
      </div>
      <img
        className="card__image margin--top-16"
        src={illustration.file.url}
        alt=""
      />
      <div className="card__text">
        <h2 className="card__title no-margin--bottom no-margin--top ">
          {title}
        </h2>
        <p
          className={cx("card__subtitle", {
            "card__subtitle--min-height": subtitleMinHeight,
          })}
        >
          {subtitle.subtitle}
        </p>
        <Link
          to={`${APP_ROUTES.journeys}/${journeySlug}/${slug}`}
          className="card__button button"
        >
          <Trans i18nKey="inspiration-card.explore_button" />
        </Link>
      </div>
    </div>
  )
}

export default InspirationCard
