import React from "react"
import { withTranslation } from "react-i18next"
import ActivityCard from "./activity-card"

const JourneySection = ({ title, subtitle, onDoActivity, activities }) => {
  return (
    <div className="margin--top-100">
      <h1 className="big-title uppercase center no-margin--bottom">{title}</h1>
      <p className="col s12 l8 offset-l2 center text">{subtitle}</p>
      <div className="row card__grid">
        {activities &&
          activities.map(activity => (
            <ActivityCard
              key={activity.contentfulId}
              {...activity}
              onDoActivity={onDoActivity}
            />
          ))}
      </div>
    </div>
  )
}

export default withTranslation()(JourneySection)
